.UI-Icon {
    display: block;
    fill: var(--color-white);
    transition: 0.3s;
}

.UI-Icon:hover {
    fill: var(--color-teal);
    transition: 0.3s;
}
