.F-Genome {
    margin: 0 50px;
    padding: 20px;
    color: var(--color-dark-blue);
    border-radius: var(--radius-35);
    background-color: var(--color-white);
}

.F-Genome__archive {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.F-Genome__new-job  {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.F-Genome__new-job-link {
    color: var(--color-teal);
    text-decoration: none;
}
