.UI-Button {
    display: block;
    padding: 20px 45px;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 35px;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
}

.UI-Button--primary {
    color: var(--color-white);
    background-color: var(--color-teal);
}

.UI-Button--ghost {
    color: var(--color-white);
    border: 1px solid var(--color-teal);
}

.UI-Button--link {
    padding: 0;
    color: var(--color-teal);
    border: none;
}

.UI-Button--small {
    padding: 10px 25px;
    border-radius: 6px;
}
