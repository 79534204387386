.UI-Select__label {
    display: block;
    margin-bottom: 20px;
}

.UI-Select__field-name {
    display: flex;
    align-items: center;
    height: 15px;
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--color-gray);
}

.UI-Select__wrap-select {
    position: relative;
}

.UI-Select__select {
    display: block;
    width: 100%;
    padding: 15px 13px;
    color: var(--color-white);
    font-size: 16px !important;
    border-radius: 5px;
    border: none;
    background-color: rgba(74, 74, 74, 0.3);
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}
