.UI-FileInput__label {
    display: block;
    min-height: 60px;
    margin-bottom: 20px;
}

.UI-FileInput__field-name {
    display: flex;
    align-items: center;
    height: 15px;
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--color-gray);
}

.UI-FileInput__fake-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 13px;
    color: var(--color-gray);
    font-size: 16px !important;
    border-radius: 5px;
    border: none;
    background-color: var(--color-gray-10);
    outline: none;
    cursor: pointer;
}
