.UI-Checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.UI-Checkbox__wrap-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-gray);
    font-weight: 500;
}

.UI-Checkbox__input {
    display: none;
}

.UI-Checkbox__input:checked:not([disabled]) ~ .UI-Checkbox__fake-box {
    border: 5px solid var(--color-gray-10);
    background-color: var(--color-teal);
}

.UI-Checkbox__fake-box {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: var(--radius);
    background-color: var(--color-gray-10);
}

.UI-Checkbox--disabled .UI-Checkbox__fake-box,
.UI-Checkbox--disabled .UI-Checkbox__label-text{
    opacity: .5;
}