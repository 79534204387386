@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../shared/assets/fonts/inter-v8-latin_cyrillic-regular.woff2') format('woff2'),
        url('../shared/assets/fonts/inter-v8-latin_cyrillic-regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../shared/assets/fonts/inter-v8-latin_cyrillic-500.woff2') format('woff2'),
        url('../shared/assets/fonts/inter-v8-latin_cyrillic-500.woff') format('woff');
}

@font-face {
    font-family: 'Gratimo Grotesk CY';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../shared/assets/fonts/Gratimo Grotesk-Regular-Web.woff2') format('woff2'),
        url('../shared/assets/fonts/Gratimo Grotesk-Regular-Web.woff') format('woff');
}

@font-face {
    font-family: 'Gratimo Grotesk CY';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../shared/assets/fonts/Gratimo Grotesk-Medium-Web.woff2') format('woff2'),
        url('../shared/assets/fonts/Gratimo Grotesk-Medium-Web.woff') format('woff');
}

:root {
    --base-font-size: 16px;
    --color-black: rgba(22, 28, 39, 1);
    --color-white: rgba(255, 255, 255, 1);
    --color-teal: rgba(20, 214, 188, 1);
    --color-teal-20: rgba(20, 214, 188, 0.2);
    --color-yellow: rgba(237, 214, 8, 1);
    --color-gray: rgba(142, 142, 147, 1);
    --color-gray-80: rgba(142, 142, 147, 0.8);
    --color-gray-30: rgba(142, 142, 147, 0.3);
    --color-gray-20: rgba(142, 142, 147, 0.2);
    --color-gray-10: #262b37;
    --color-dark-blue: rgba(22, 28, 39, 1);
    --color-red: rgba(225, 2, 15, 1);
    --color-red-30: rgba(225, 2, 15, 0.3);
    --color-red-10: rgba(225, 2, 15, 0.1);
    --container-max-width: 1200px;
    --radius: 4px;
    --radius-35: 35px;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font-size: var(--base-font-size);
    color: var(--color-black);
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    background-image: url('../shared/assets/images/bg.svg');
    background-size: cover;
}

h1,
h2 {
    margin-top: 0;
    font-family: 'Gratimo Grotesk CY', sans-serif;
    font-weight: 500;
}

a {
    color: var(--color-teal);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.App {
    min-height: 100vh;
    color: white;
}

.App__header {
    margin-bottom: 50px;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid var(--color-gray-20);
}

.App__header-wrap-items {
    display: flex;
    align-items: center;
}

.App__logo {
    margin-right: 60px;
}

.App__menu-ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.App__menu-li {
    margin-right: 35px;
}

.App__menu-li:last-child {
    margin-right: 0;
}

.App__menu-link {
    color: var(--color-white);
    text-decoration: none;
    transition: 0.3s;
}

.App__menu-link:hover {
    color: var(--color-teal);
    transition: 0.3s;
}

.App__out-wrap-links {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.App__out-link {
    display: flex;
    margin-right: 35px;
    color: var(--color-white);
    text-decoration: none;
    transition: 0.3s;
}

.App__out-link:last-child {
    margin-right: 0;
}

.App__out-link:hover {
    color: var(--color-teal);
    transition: 0.3s;
}

.App__title {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 40px auto;
    font-family: 'Gratimo Grotesk CY', sans-serif;
    text-align: center;
    font-weight: 500;
}

.slider {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto 25px auto;
    padding: 25px;
    border-radius: 20px;
    background-color: #262b37;
}

.slider__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.slider__title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.slider__title svg {
    display: block;
}

.App__footer {
    margin-top: 80px;
    padding: 25px 0;
    text-align: center;
    color: var(--color-gray);
    font-size: 14px;
}

.container {
    max-width: var(--container-max-width);
    margin: 0 auto;
    padding: 0 50px;
}
